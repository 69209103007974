import React from 'react';

const TagContainer = (props) => {
  const css = {
    display: 'block',
    justifyContent: 'flex-start',
    columnGap: '1rem',
    alignSelf: 'flex-start',
  };

  return <div style={css}>{props.children}</div>;
};

export default TagContainer;
