import * as styles from './CardGrid.module.css';

import React from 'react';

const CardGrid = (props) => {
  const propStyles = {
    display: props.show ? 'grid' : 'hide',
    maxWidth: props.maxWidth ? `${props.maxWidth}px` : 'unset',
  };

  return (
    <div className={styles.CardGrid} style={propStyles}>
      {props.children}
    </div>
  );
};

export default CardGrid;
