import * as styles from './BlogCard.module.css';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Link } from 'gatsby';
import React from 'react';
import Tag from '../Tag/Tag';
import TagContainer from '../Tag/TagContainer';

const BlogCard = (props) => {
  const getTags = (tags) => {
    if (props.tags) {
      return props.tags.slice(0, 3);
    }
    return [];
  };
  const tagList = getTags(props.tags);

  const { img } = props;

  return (
    <div className={styles.BlogCard}>
      <Link to={props.link}>
        <div className={styles.Card}>
          <div className={styles.Image}>
            {typeof img === 'string' ? (
              <img src={img} alt={props.title} />
            ) : (
              <GatsbyImage image={img} />
            )}
          </div>
          <div className={styles.BlogInfo}>
            <p className={styles.Title}>{props.title}</p>
            <p>{props.author}</p>
            <p className={styles.Date}>{props.date}</p>
          </div>
        </div>
      </Link>
      <TagContainer>
        {props.tags &&
          tagList.map((el, i) => {
            return <Tag key={i} tag={el}></Tag>;
          })}
      </TagContainer>
    </div>
  );
};

export default BlogCard;
