import * as styles from './Tag.module.css';

import { Link } from 'gatsby';
import React from 'react';
import slugify from 'slugify';

const Tag = (props) => {
  return (
    <Link
      to={`/blog/tags/${slugify(props.tag, { lower: true })}`}
      className={styles.Tags}>
      {props.tag}
    </Link>
  );
};

export default Tag;
