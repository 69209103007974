import * as styles from '../styles/blogPost.module.css';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { getBlogPostTags, getUrl } from '../util';

import BlockRow from '../components/UI/BlockRow/BlockRow';
import BlogCard from '../components/Card/BlogCard';
import CardGrid from '../containers/CardGrid/CardGrid';
import Layout from '../containers/layout';
import MarkdownIt from 'markdown-it';
import React from 'react';
import Tag from '../components/Tag/Tag';
import TagContainer from '../components/Tag/TagContainer';
import { setTrue } from '../state/slices';
import slugify from 'slugify';
import { useDispatch } from 'react-redux';

const Blog = ({ data, location }) => {
  const dispatch = useDispatch();

  const bioPages = data.allStrapiTeamMembers.nodes.filter(i=>i.HasBioPage).map(i=>i.Name.toLowerCase().replace(/[^a-z]/g, ''));
  const blogs = data.allStrapiBlogs.nodes;
  const bannerPages = data.allSitePage.nodes;
  const article = data.strapiBlogs;
  const DefaultImage =
    data.strapiGeneralSeo.SiteSEO.PreviewImage.formats.medium.url;

  const moreBlogs = blogs.map((el, i) => {
    return (
      <BlogCard
        title={el.Title}
        link={getUrl(bannerPages, el.id)}
        key={i}
        img={el.CoverImage ? getImage(el.CoverImage.localFile) : DefaultImage}
        date={el.Date}
      />
    );
  });

  const blogCards = moreBlogs.filter(el=>article.SEO.MetaDescription!==el.props.title).slice(0, 4);

  const md = new MarkdownIt();
  const content = md.render(article.Content);

  const seoData = {
    Date: article.Date,
    Author: article.Author,
    ...data.strapiBlogs.SEO,
  };

  const tags = getBlogPostTags(data.strapiBlogs);

  return (
    <Layout seoData={seoData} location={location}>
      <BlockRow mobileReverse>
        <div className={styles.Image}>
          <GatsbyImage
            image={getImage(article.CoverImage.localFile)}
            className={styles.Image}
          />
        </div>
        <div className={styles.Content}>
          <h1>{article.Title}</h1>
          <h4>
            {/* check if the author name is in the bio page array */}
            {bioPages.includes(article.Author.toLowerCase().replace(/[^a-z]/g, '')) ? (
            <Link to={`/about/team/${slugify(article.Author, { lower: true })}`}>
              {article.Author}
            </Link>
            ) : (
              article.Author
            )}
          </h4>
          <p className={styles.Date}>{article.Date}</p>
        </div>
      </BlockRow>
      <BlockRow mobileReverse>
        <TagContainer>
          {tags.map((el, i) => {
            return <Tag tag={el} key={i} />;
          })}
        </TagContainer>
        <div className={styles.ContentWrapper}>
          <section>
            <div
              className={styles.Content}
              dangerouslySetInnerHTML={{ __html: content }}></div>
            <br />
            <div
              className={styles.CallToAction}
              onClick={() => dispatch(setTrue())}>
              <p>
                <strong>{article.CTA}</strong>
              </p>
            </div>
          </section>
        </div>
      </BlockRow>
      <h3>RECENT POSTS</h3>
      <CardGrid show>{blogCards}</CardGrid>
    </Layout>
  );
};

export const query = graphql`
  query blogQuery($id: String!) {
    strapiBlogs(id: { eq: $id }) {
      id
      Author
      Title
      Tags
      CoverImage {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              layout: FULL_WIDTH
              quality: 100
            )
          }
        }
      }
      Date(formatString: "DD MMMM, YYYY")
      Content
      CTA
      SEO {
        MetaTitle
        MetaDescription
        Slug
        Keywords
        PreviewImage {
          url
          width
          height
          formats {
            small {
              url
              width
              name
              height
            }
          }
        }
      }
    }
    allStrapiBlogs(limit: 8, sort: { order: DESC, fields: Date }) {
      nodes {
        id
        Title
        Author
        Date(formatString: "DD MMMM, YYYY")
        CoverImage {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 100)
            }
          }
        }
      }
    }
    allSitePage(
      filter: {
        componentChunkName: { eq: "component---src-templates-blog-js" }
      }
    ) {
      nodes {
        context {
          id
        }
        path
      }
    }
    allStrapiTeamMembers {
      nodes {
        HasBioPage
        MeetingLink
        Name
      }
    }
    strapiGeneralSeo {
      SiteSEO {
        PreviewImage {
          formats {
            medium {
              url
              name
            }
          }
        }
      }
    }
  }
`;

export default Blog;
